import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from  '@angular/common/http';
import { AlertController, ToastController, LoadingController } from '@ionic/angular';
import { Observable, BehaviorSubject, of, throwError } from  'rxjs';
import { tap, catchError, map } from  'rxjs/operators';

const httpOptions = {
	headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
//const apiUrl = 'http://localhost/sipteam/api/v1';
const apiUrl = 'https://sipteam.storm.expert/api/v1';

export class User {
    token: string;
    tipo: string;
}

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private _ddd: any;
	private _query: any;
	private _userSubject: BehaviorSubject<User>;
	public _user: Observable<User>;
	private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private pf: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		private  toastController: ToastController,
        private alertCtrl: AlertController,
		private http: HttpClient,
		public loadingController: LoadingController
	) {
		this._userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('_usuario')));
		if (this._userSubject.value) {
			this.loggedIn.next(true);

			let tipo = this._userSubject.value.tipo;
			if(tipo == "0"){
				this.pf.next(true);
			}
		}
		this._user = this._userSubject.asObservable();
	}

	get isLoggedIn() {
		return this.loggedIn.asObservable();
	}

	get _pfValue() {
		return this.pf.asObservable();
	}

	public get _userValue(): User {
		return this._userSubject.value;
	}

    public presentToast($msg) {
        let toast = this.toastController.create({
            color: "dark", //success, tertiary, danger, light
            message: $msg,
            position: 'bottom', //"bottom" | "middle" | "top"
            translucent: true,
            duration: 2000
        }).then((toastData) => {
	      	toastData.present();
	    });
    }

	async presentLoadingWithOptions(_message: any) {
		const loading = await this.loadingController.create({
			id: 'loja',
			cssClass: 'custom-class custom-loading',
			spinner: 'circles',
			message: _message,
			translucent: true
		});
		await loading.present();
	}	

    async alerta(_header: any, _message: any) {
        const alert = await this.alertCtrl.create({
        	cssClass: 'alerta',
            header: _header,
            subHeader: '',
            message: _message,
            buttons: ['OK']
        });
        await alert.present();
    }

	async alerta_toast(_icon: any, _message: any) {
		const toast = await this.toastController.create({
			message: _message,
			duration: 2000,
			position: 'bottom',
			buttons: [
				{
					side: 'start',
					icon: _icon,
					text: '',
				}
			]
		});
		toast.present();
	}

	public login(usuario: string, senha: string) {
		return this.http.post<any>(`${apiUrl}/login`, { email: usuario, senha: senha }).pipe(
				map(response => {
					if (response.token) {
						localStorage.setItem('_token', JSON.stringify(response.token));
						localStorage.setItem('_usuario', JSON.stringify(response.data.usuario));
						this._userSubject.next(response.data);
						this.loggedIn.next(true);
						if (response.data.usuario.tipo == 0){
							this.pf.next(true);
						}
						return response.data;
					}else{
						return false;
					}
				}));
	}

	public rotas() {
		let httpOptions = new HttpHeaders().set("Content-Type", "application/json");
  		return  this.http.get<any>(`${apiUrl}/ping`, { headers: httpOptions }).pipe(map(response => {					
					return response;
				}));
	}

	public logout() {
		localStorage.removeItem('_token');
		localStorage.removeItem('_usuario');

		this._userSubject.next(null);
		this.loggedIn.next(false);
		this.pf.next(false);
	}





	

	public lojas(event, categoria) {
		let httpOptions = new HttpHeaders().set("Content-Type", "application/json");
		categoria = typeof categoria !== 'undefined' ? categoria : '';
		
		let urloja = `${apiUrl}/lojas`;
		if (categoria != ""){
			urloja = `${apiUrl}/lojas/${categoria}`;			
		}

		let _ddd = localStorage.getItem("_ddd") || '11';
		urloja = urloja+"?ddd="+_ddd;

		let _query = localStorage.getItem("_query") || null;
		if (_query !== null){
			urloja = urloja+"&q="+_query;
			localStorage.removeItem('_query');
		}

  		return  this.http.get<any>(urloja, { headers: httpOptions }).pipe(map(response => {
					if (event){
						event.target.complete();
					}
					if (response.data) {
						return response.data;
					}else{
						return false;
					}
				}));
	}

	public loja(lojaId) {
		//this.presentLoadingWithOptions('Carregando...');
		let httpOptions = new HttpHeaders().set("Content-Type", "application/json");
  		return  this.http.get<any>(`${apiUrl}/loja/${lojaId}`, { headers: httpOptions }).pipe(map(response => {
  					//this.loadingController.dismiss('loja');
					if (response.data) {
						return response.data;
					}else{
						return false;
					}
				}));
	}

	public cadastro(dados: string) {
		return this.http.post<any>(`${apiUrl}/cadastro`, { dados: dados }).pipe(
				map(response => {
                    if (response.token) {
						localStorage.setItem('_token', JSON.stringify(response.token));
						localStorage.setItem('_usuario', JSON.stringify(response.data.usuario));
						this._userSubject.next(response.data);
						this.loggedIn.next(true);
						if (response.data.usuario.tipo == 0){
							this.pf.next(true);
						}
                    }

					return response;
				}));
	}

	public verificaPJ(logado: string, negocio: string, dados: string) {
		return this.http.post<any>(`${apiUrl}/verifica_pj`, { logado: logado, negocio: negocio, dados: dados }).pipe(
				map(response => {
					return response;
				}));
	}

	public cadastroPJ(senderhash:string, logado: string, negocio: string, dados: string, plano: string, cartao: string) {
		return this.http.post<any>(`${apiUrl}/cadastro_pj`, { senderhash: senderhash, logado: logado, negocio: negocio, dados: dados, plano: plano, cartao: cartao }).pipe(
				map(response => {
					return response;
				}));
	}

	public cadastroPJ_free(logado: string, negocio: string, dados: string) {
		return this.http.post<any>(`${apiUrl}/cadastro_pj_free`, { logado: logado, negocio: negocio, dados: dados}).pipe(
				map(response => {
					return response;
				}));
	}

	public atualizar_pj(dados: string, galeria: string) {
		let token = localStorage.getItem('_token');
		token = token.replace(/"/g, "");
		let httpOptions = new HttpHeaders().set('Authorization', 'Bearer ' + token).set("Content-Type", "application/json");

		return this.http.post<any>(`${apiUrl}/atualizar_pj`, { dados: dados, galeria: galeria}, { headers: httpOptions }).pipe(
				map(response => {
					if (response.data) {
						return response.data;
					}else{
						return false;
					}
				}));
	}

	public assinar_anuncio(senderhash:string, plano: string, cartao: any, logado: string) {
		let token = localStorage.getItem('_token');
		token = token.replace(/"/g, "");
		let httpOptions = new HttpHeaders().set('Authorization', 'Bearer ' + token).set("Content-Type", "application/json");

		return this.http.post<any>(`${apiUrl}/assinar_anuncio`, { senderhash: senderhash, plano: plano, cartao: cartao, logado: logado}, { headers: httpOptions }).pipe(
				map(response => {
					//if (response.data) {
						return response;
					/*}else{
						return false;
					}*/
				}));
	}

	public minha_loja() {
		let token = localStorage.getItem('_token');
		token = token.replace(/"/g, "");
		let httpOptions = new HttpHeaders().set('Authorization', 'Bearer ' + token).set("Content-Type", "application/json");

  		return  this.http.get<any>(`${apiUrl}/minha_loja`, { headers: httpOptions }).pipe(map(response => {
					if (response.data) {
						return response.data;
					}else{
						return null;
					}
				}));
	}

	public loja_favorita(lojaId) {
		let token = localStorage.getItem('_token');
		token = token.replace(/"/g, "");
		let httpOptions = new HttpHeaders().set('Authorization', 'Bearer ' + token).set("Content-Type", "application/json");

		return this.http.post<any>(`${apiUrl}/loja_favorita`, { lojaId: lojaId}, { headers: httpOptions }).pipe(
				map(response => {
					if (response.status) {
						this.alerta_toast('heart', response.status);
						return response.status;
					}else{
						this.alerta_toast('heart-outline', response.status);
						return false;
					}
				}));
	}

	public lojas_favoritas(event) {
		let token = localStorage.getItem('_token');
		token = token.replace(/"/g, "");
		let httpOptions = new HttpHeaders().set('Authorization', 'Bearer ' + token).set("Content-Type", "application/json");

  		return  this.http.get<any>(`${apiUrl}/lojas_favoritas`, { headers: httpOptions }).pipe(map(response => {
					if (event){
						event.target.complete();
					}
					if (response.data) {
						return response.data;
					}else{
						return null;
					}
				}));
	}

	public perfil(dados: string) {
		let token = localStorage.getItem('_token');
		token = token.replace(/"/g, "");
		let httpOptions = new HttpHeaders().set('Authorization', 'Bearer ' + token).set("Content-Type", "application/json");

		return this.http.post<any>(`${apiUrl}/perfil`, { dados: dados }, { headers: httpOptions }).pipe(
				map(response => {
					if (response.data) {
						localStorage.setItem('_usuario', JSON.stringify(response.data));
					}
					return response.status;
				}));
	}

	public senha(dados: string) {
		let token = localStorage.getItem('_token');
		token = token.replace(/"/g, "");
		let httpOptions = new HttpHeaders().set('Authorization', 'Bearer ' + token).set("Content-Type", "application/json");

		return this.http.post<any>(`${apiUrl}/senha`, { dados: dados }, { headers: httpOptions }).pipe(
				map(response => {
					return response.status;
				}));
	}

	public anuncio(dados: string, galeria: string) {
		let token = localStorage.getItem('_token');
		token = token.replace(/"/g, "");
		let httpOptions = new HttpHeaders().set('Authorization', 'Bearer ' + token).set("Content-Type", "application/json");

		return this.http.post<any>(`${apiUrl}/anuncio`, { dados: dados, galeria: galeria }, { headers: httpOptions }).pipe(
				map(response => {
					return response.status;
				}));
	}

	public anuncios_disponiveis(event) {
		let token = localStorage.getItem('_token');
		token = token.replace(/"/g, "");
		let httpOptions = new HttpHeaders().set('Authorization', 'Bearer ' + token).set("Content-Type", "application/json");

  		return  this.http.get<any>(`${apiUrl}/anuncios_disponiveis`, { headers: httpOptions }).pipe(map(response => {
					if (event){
						event.target.complete();
					}
					//if (response.data) {
						return response;
					//}else{
					//	return null;
					//}
				}));
	}

	public faq_temas(event) {
		let httpOptions = new HttpHeaders().set("Content-Type", "application/json");
		let urloja = `${apiUrl}/faq_temas`;
		
  		return  this.http.get<any>(urloja, { headers: httpOptions }).pipe(map(response => {
					if (event){
						event.target.complete();
					}
					if (response.data) {
						return response.data;
					}else{
						return false;
					}
				}));
	}

	public faq_temas_pr(tema) {
		let httpOptions = new HttpHeaders().set("Content-Type", "application/json");
  		return  this.http.get<any>(`${apiUrl}/faq_temas_pr/${tema}`, { headers: httpOptions }).pipe(map(response => {
					if (response.data) {
						return response.data;
					}else{
						return false;
					}
				}));
	}

	public faq_pf() {
		let httpOptions = new HttpHeaders().set("Content-Type", "application/json");
		let urloja = `${apiUrl}/faq_pf`;
		
  		return  this.http.get<any>(urloja, { headers: httpOptions }).pipe(map(response => {
					if (response.data) {
						return response.data;
					}else{
						return false;
					}
				}));
	}






	public estrutura(event) {
		let token = localStorage.getItem('_token');
		token = token.replace(/"/g, "");
		let httpOptions = new HttpHeaders().set('Authorization', 'Bearer ' + token).set("Content-Type", "application/json");
  		return  this.http.get<any>(`${apiUrl}/estrutura`, { headers: httpOptions }).pipe(map(response => {
					event.target.complete();
					if (response.data) {
						localStorage.setItem('_acoes', JSON.stringify(response.data.acoes));
						localStorage.setItem('_hospitais', JSON.stringify(response.data.hospitais));
						localStorage.setItem('_categorias', JSON.stringify(response.data.categorias));
						return response.data;
					}else{
						return false;
					}
				}));
	}

    public doRefresh(event) {
    	if (!navigator.onLine){
    		console.log("sem internet");
    	}else{    		
    		console.log("com internet");
    	}

        console.log('Begin async operation');
        setTimeout(() => {
            console.log('Async operation has ended');
            event.target.complete();
        }, 2000);
    }

	public sincronizar() {
		//this.presentLoadingWithOptions();

        let _anjoMonitora : {};
        _anjoMonitora = JSON.parse(localStorage.getItem("_anjoMonitora")) || {};

		let token = localStorage.getItem('_token');
		token = token.replace(/"/g, "");
		let headers = new HttpHeaders().set("Authorization", "Bearer "+token).set("Content-Type", "application/json");

		return this.http.post<any>(`${apiUrl}/monitora`, { dados: JSON.stringify(_anjoMonitora)}, { headers: headers }).pipe(
				map(response => {
					//this.loadingController.dismiss('sincronizar');
					if (response.status) {
						localStorage.removeItem("_anjoMonitora");
						this.alerta('Confirmação', 'Monitoramentos enviados com sucesso.');
						return response.status;
					}else{
						this.alerta('Atenção', 'Erro ao enviar os dados.');
						return false;
					}
				}));	

	}

	public monitoramentos(event) {
		let token = localStorage.getItem('_token');
		token = token.replace(/"/g, "");
		let httpOptions = new HttpHeaders().set('Authorization', 'Bearer ' + token).set("Content-Type", "application/json");
  		return  this.http.get<any>(`${apiUrl}/monitoramentos`, { headers: httpOptions }).pipe(map(response => {
  					if (event) event.target.complete();
					if (response.data) {
						localStorage.setItem('_monitoramentos', JSON.stringify(response.data.monitora));
						return response.data;
					}else{
						return false;
					}
				}));
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			return of(result as T);
		};
	}
}
