<ion-app>
    <ion-split-pane contentId="main-content">
        <ion-menu contentId="main-content" type="overlay">
            <ion-content class="ion-no-padding">

                <div class="menu_header" *ngIf="isLoggedIn$ | async as isLoggedIn; else logoff">
                    <ion-menu-toggle autoHide="false">
                        <ion-item lines="none" routerLink="/app/perfil" routerLinkActive="active" detail="false">
                            <ion-avatar slot="start">
                                <ion-icon name="person"></ion-icon>
                            </ion-avatar>
                            <ion-label>
                            <h3>Eloy</h3>

                            <p>Meus dados</p>
                            </ion-label>
                        </ion-item>
                    </ion-menu-toggle>
                </div>
                <ion-list id="inbox-list" *ngIf="isLoggedIn$ | async as isLoggedIn;">
                    <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
                        <ion-item routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false" routerLinkActive="selected">
                            <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
                            <ion-label>{{ p.title }}</ion-label>
                        </ion-item>
                    </ion-menu-toggle>

                    <ion-menu-toggle auto-hide="false">
                        <ion-item button (click)="logout()" detail="false" lines="none">
                            <ion-icon slot="start" [ios]="'log-out-outline'" [md]="'log-out-sharp'"></ion-icon>
                            <ion-label>Sair</ion-label>
                        </ion-item>
                    </ion-menu-toggle>
                </ion-list>




                <ng-template #logoff>
                    <div class="menu_header">
                        <ion-menu-toggle autoHide="false">
                            <ion-item lines="none" routerLink="/login" routerLinkActive="active" detail="false">
                                <ion-avatar slot="start">
                                    <ion-icon name="person"></ion-icon>
                                </ion-avatar>
                                <ion-label>
                                    <h3>Seja bem-vindo</h3>
                                    <p>Efetue seu Login</p>
                                </ion-label>
                            </ion-item>
                        </ion-menu-toggle>
                    </div>
                    <ion-list id="inbox-list">
                        <ion-menu-toggle auto-hide="false">
                            <ion-item routerDirection="root" routerLink="/app/ajuda" lines="none" detail="false" routerLinkActive="selected">
                                <ion-icon slot="start" [ios]="'help-buoy-outline'" [md]="'help-buoy-sharp'"></ion-icon>
                                <ion-label>Ajuda</ion-label>
                            </ion-item>
                        </ion-menu-toggle>
                        <ion-menu-toggle auto-hide="false">
                            <ion-item routerDirection="root" routerLink="/app/termos" lines="none" detail="false" routerLinkActive="selected">
                                <ion-icon slot="start" [ios]="'information-circle-outline'" [md]="'information-circle-sharp'"></ion-icon>
                                <ion-label>Termos e Condições</ion-label>
                            </ion-item>
                        </ion-menu-toggle>
                    </ion-list>
                </ng-template>




                <div class="menu-background ion-no-padding">
                    <img src="assets/img/road.png"/>
                </div>
            </ion-content>
        </ion-menu>
        <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
</ion-app>
