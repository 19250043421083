import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';
import { AuthService } from './auth/auth.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
    _usuario: any;
    isLoggedIn$: Observable<boolean>;
    pfValue$: Observable<boolean>;
    appPages = [
        {
            title: 'Início',
            url: '/app/home',
            icon: 'calendar'
        },
        {
            title: 'Corridas',
            url: '/app/corridas',
            icon: 'time'
        },
        {
            title: 'Configurações',
            url: '/app/config',
            icon: 'settings'
        },
        {
            title: 'Ajuda',
            url: '/app/ajuda',
            icon: 'help-buoy'
        },
        {
            title: 'Termos e Condições',
            url: '/app/termos',
            icon: 'information-circle'
        }
    ];
    loggedIn = false;
    dark = false;

    constructor(
        public authService: AuthService,
        private menu: MenuController,
        private platform: Platform,
        private router: Router,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private storage: Storage,
        private toastCtrl: ToastController,
    ) {
        this.initializeApp();
    }

    async ngOnInit() {
        this.isLoggedIn$ = this.authService.isLoggedIn;
        this.pfValue$ = this.authService._pfValue;
    }

    logout() {
        this.authService.logout();
        return this.router.navigateByUrl('/login');
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
        
    }
}