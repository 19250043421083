import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppGuard } from './app.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/app/home',
        pathMatch: 'full'
    },
    /*{ 
        path: '**', 
        redirectTo: '/app/home' 
    },*/
    {
        path: 'login',
        children: [
            {
                path: '',
                loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
            },
            {
                path: 'senha',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('./pages/login/senha/senha.module').then(m => m.SenhaPageModule)
                    }
                ]
            }
        ]
    },
    {
        path: 'app/home',
        canActivate: [AppGuard],
        loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
    },
    {
        path: 'app/corridas',
        canActivate: [AppGuard],
        loadChildren: () => import('./pages/corridas/corridas.module').then( m => m.CorridasPageModule)
    },
    {
        path: 'app/config',
        canActivate: [AppGuard],
        loadChildren: () => import('./pages/config/config.module').then( m => m.ConfigPageModule)
    },
    {
        path: 'perfil',
        canActivate: [AppGuard],
        loadChildren: () => import('./pages/perfil/perfil.module').then( m => m.PerfilPageModule)
    },
    {
        path: 'app/ajuda',
        loadChildren: () => import('./pages/ajuda/ajuda.module').then( m => m.AjudaPageModule)
    },
    {
        path: 'app/termos',
        loadChildren: () => import('./pages/termos/termos.module').then( m => m.TermosPageModule)
    },
    {
        path: 'cadastro',
        loadChildren: () => import('./pages/cadastro/cadastro.module').then( m => m.CadastroPageModule)
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
